import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { MatChipInputEvent } from "@angular/material/chips";
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  emailDetails;
  attachedFile;
  readonly separatorKeysCodes: any[] = [ENTER, COMMA]
  Resx: any = {};
  isRequiredEmail = false;
  isGenerated = false;
  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SendEmailComponent>,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public myData: any) {
  }
  @HostListener("window:keyup.esc") onKeyUp() {
    this.close();
  }
 
  close() {
    this.dialogRef.close();
  }
  ngOnInit() {
  
    this.emailDetails = this.fb.group({
      
      to: [[], Validators.required],
      subject: [this.myData, Validators.required]
    });
  }
  inValidEmail = false;
  addEmailList(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    // Add our status
    if ((value || "").trim()) {
      let regexp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
      if (regexp.test(value.trim())) {
        this.emailDetails.get("to").value.push(value.trim());
        this.inValidEmail = false;
        // Reset the input value
        if (input) {
          input.value = "";
        }
        if (this.emailDetails.get("to").value.length === 1) {
          this.emailDetails.controls["to"].updateValueAndValidity();
          this.isRequiredEmail = false;
        }
      }
      else {
        this.inValidEmail = true;
      }
    } else {
      this.inValidEmail = false;
    }
  }
  changeValue(){
    
  }
  removeEmailList(item: any) {
    const index = this.emailDetails.get("to").value.indexOf(item);
    if (index >= 0) {
      this.emailDetails.get("to").value.splice(index, 1);
      if (this.emailDetails.get("to").value.length === 0) {
        this.emailDetails.controls["to"].updateValueAndValidity();
      }
    }
  }
  send() {
    if (this.emailDetails.valid) {
     // let emailDetails = this.emailDetails.getRawValue();
     // emailDetails.to =emailDetails.to.join(";");
    //  this.dialogRef.close(emailDetails);
      this.dialogRef.close(this.emailDetails.getRawValue());
  
    }
    else {
      this.emailDetails.markAllAsTouched();
      if (!this.emailDetails.controls["to"].valid) {
       
      }
    }
  }
}
