import {
  Component,
  OnInit,

  ViewChild,
  OnDestroy,

} from "@angular/core";
import { ActivatedRoute, Router, RouterEvent, NavigationEnd, } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { SigmentsService } from "src/app/services/sigments.service";
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Subject } from 'rxjs';
import { filter, takeUntil, } from 'rxjs/operators';
// import {
//   animate,
//   state,
//   style,
//   transition,
//   trigger,
// } from "@angular/animations";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  public destroyed = new Subject<any>();
  // data: any = {
  //   LOGISTIC: { isActive: false, DataRows: [] },
  //   AI: { isActive: false, DataRows: [] },
  //   CB: { isActive: false },
  //   AE: { isActive: false, DataRows: [] },
  //   DELIVERY: { isActive: false, DataRows: [] },
  //   OI: { isActive: false, DataRows: [] },
  //   OE: { isActive: false, DataRows: [] },
  //   CR: { isActive: false },
  //   FN: { isActive: false },
  //   VS: { isActive: false, DataRows: [] },
  //   IN: { isActive: false },
  //   FOLLOW: { isActive: false, DataRows: [] },
  // };
  // icons: any = {
  //   LOGISTIC: "domain",
  //   AI: "flight_land",
  //   CB: "",
  //   AE: "flight_takeoff",
  //   DELIVERY: "local_shipping",
  //   OI: "directions_boat",
  //   OE: "directions_boat",
  //   CR: "",
  //   FN: "",
  //   VS: "rv_hookup",
  //   IN: "",
  //   FOLLOW: "list_alt"
  // };
  isLoading = false;
  //categoryList: any[];
  //Resx: any;
  orgList: any;
  isPowerUser: boolean=undefined;
  selectedOrg: any;
  orgName: any;
  filter: any;
  constructor(
    public service: AuthService,
    private sigmentsSrv: SigmentsService,
    public http: HttpClient,
    private router: Router
  ) {
  }
  SetOrgForPowerUser(org) {
 localStorage.setItem("orgName", org.Name);
      localStorage.setItem("orgCrm", org.CRM);
    this.sigmentsSrv.setPowerUserOrg(org.CRM).then((success) => {
       
      if (success) {

         this.router.navigate(["/home"]);
         setTimeout(() => { 
          this.router.navigate(["/home/dashboard"]); 
        }, 500);
       // this.router.navigate(["/home/dashboard"]);
        // this.sigmentsSrv.getIsPoweUser().then((isPowerUser) => {
        //   this.isPowerUser = isPowerUser;
        //   if (this.isPowerUser) {
        //     this.sigmentsSrv.getOrgList().then((orgList) => {
        //       console.log("SetOrgForPowerUser")
        //       this.orgList = orgList;
        //     });
        //   }
        // });
      }
    });
  }
  LogOut() {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("expiredDate");
    this.router.navigate(["/login"]);
  }
  // getDataFromApi(isForceLoad): void {
  //   if (this.sigmentsSrv.stageOneData != null && !isForceLoad) {
  //     this.data = this.sigmentsSrv.stageOneData;
  //     this.isLoading = false;
  //   } else {
  //     this.sigmentsSrv.getStageOneData().then((data) => {
  //       this.data = data;
  //       this.isLoading = false;
  //     });
  //   }
  //   if (this.sigmentsSrv.sigmentsCategory != null && !isForceLoad) {
  //     this.categoryList = this.sigmentsSrv.sigmentsCategory;

  //   } else {
  //     this.sigmentsSrv.getSigmentsCategory().then((categoryJson) => {
  //       this.categoryList = categoryJson;
  //     });
  //   }
  // }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
  ngOnInit() {
    this.isLoading = true;
    // this.sigmentsSrv.GetResx().then((resx) => {
    //   this.Resx = resx;
    // });
   // this.getDataFromApi(false);
    this.service.GetUser();
    //this.sigmentsSrv.change.subscribe((orgName) => {
      this.sigmentsSrv.getOrgForUser().subscribe((org) => {
      this.orgName = org.Name;
      localStorage.setItem("orgName", this.orgName);
      localStorage.setItem("orgCrm", org.CRM);
     // this.SetOrgForPowerUser(this.orgName);
      this.sigmentsSrv.getIsPoweUser().then((isPowerUser) => {
        this.isPowerUser = isPowerUser;
        if (this.isPowerUser) {
          this.sigmentsSrv.getOrgList().then((orgList) => {
            //this.selectedOrg = orgList[0];
            // orgList.splice(0, 1);
            this.orgList = orgList;
            if (orgList.length > 0)
              this.selectedOrg = orgList.filter(o => o.Name == this.orgName)[0];
          
          });
        }
      });
    });

  }
  ExportData(): void {
    this.isLoading = true;
    this.sigmentsSrv.getExcel().then((data) => {
      const blob: Blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName: string = this.sigmentsSrv.orgName;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement(
        "a"
      ) as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      this.isLoading = false;
      URL.revokeObjectURL(objectUrl);
    });
  }
}
