import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SigmentsService } from 'src/app/services/sigments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-documents',
  templateUrl: './show-documents.component.html',
  styleUrls: ['./show-documents.component.scss']
})
export class ShowDocumentsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public myData: any,private sigmentsSrv: SigmentsService,private dialogRef: MatDialogRef<ShowDocumentsComponent>,) { }

  ngOnInit(): void {
  }
  OpenImportDoc(_url) {
    var rootUrl = environment.GetApiRootUrl();
    if (_url.includes(rootUrl)) {
      this.sigmentsSrv.getPdf(_url).then((data) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      });
    } else {
      window.open(_url, "_blank");
    }
  }
  getLastPOD(linkPOD) {
    linkPOD = linkPOD.replace(":", "_points_");
    this.sigmentsSrv.getLastDoc(linkPOD).then((data) => {
      if (data != null) {
        var file = new Blob([data], { type: "image/png" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
      }
    });
  }
  close() {
    this.dialogRef.close();
  }
}
