import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root",
})
export class BookingService {
  header: HttpHeaders;
  readonly url = environment.GetApiRootUrl();
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }


  list(bookingSearch): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.post<any>(this.url + "api/booking/list", bookingSearch, {
      headers: this.header,
    });
  }
  getBookingContainerList(book): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(`${this.url}api/booking/getBookingContainerList/${book}`, {
      headers: this.header,
    });
  }
  getBookingParamByType(): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(this.url + "api/booking/bookingParamByType", {
      headers: this.header,
    });
  }
  saveBookingParamByType(type, param): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(this.url + "api/booking/saveBookingParamByType/" + type + "/" + param, {
      headers: this.header,
    });
  }
  save(book): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.post<any>(this.url + "api/booking/save", book, {
      headers: this.header,
    });
  }

  getNilitPreviousVessel(book): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(this.url + "api/booking/getNilitPreviousVessel/" + book, {
      headers: this.header,
    });
  }
}