import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: "root"
})
export class FileService {
  readonly url = environment.GetApiRootUrl();
  header: HttpHeaders;
  constructor(private http: HttpClient, private authService: AuthService) { }

  downloadFile(invoice): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.url + "api/files/download/" + invoice, { headers: this.header, responseType: "blob" as "json" })
        .subscribe((_pdf) => {
          resolve(_pdf);
        });
    });
    return promise;
  }

  downloadFilePriorityInvoices(invoice): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.url + "api/files/downloadPriorityInvoices/" + invoice, { headers: this.header, responseType: "blob" as "json" })
        .subscribe((_pdf) => {
          resolve(_pdf);
        });
    });
    return promise;
  }
}