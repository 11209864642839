import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from "@angular/material";
import { exportType } from 'src/app/models/export-type.model';
import { ExportService } from 'src/app/services/export.service';
import { FileService } from 'src/app/services/file.service';
import { ReportsService } from "src/app/services/reports.service";
import { SigmentsService } from 'src/app/services/sigments.service';
import { environment } from 'src/environments/environment';
import { AddEmaillSnackBarComponent } from '../add-emaill-snack-bar/add-emaill-snack-bar.component';
import { SendEmailComponent } from "../send-email/send-email.component";
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  displayedColumns = [];
  displayedColumnsSearch = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  data: any;
  lastFileName: any;
  fileName: any;
  isLoading: boolean;
  reportList: any[];
  currentReport: string;
  filterEntity: any = {};
  widthOfReportTable = "100%"
  columnToAddList = [];
  disabledSendMail = false;
  isDisplaySearch=false;
  constructor( private dialog: MatDialog,private reportsService: ReportsService, private _snackBar: MatSnackBar, private exportService: ExportService, private fileService: FileService) {
  }
  deleteColumn(colName) {
    let index = this.displayedColumns.indexOf(colName)
    this.columnToAddList.push({ "colName": colName, "index": index });
    this.displayedColumns.splice(index, 1);
    this.displayedColumnsSearch.splice(index, 1);
    this.widthOfReportTable = `${this.displayedColumns.length * 141}px`;
  }

  ngOnInit() {


    this.reportsService.getReports().subscribe((data) => {
      this.reportList = data
    });
  }
  isLinkIDColumn(disCol) {
    if (disCol.match(/LINKID.*/)) {
      return true;
    }
    return false;
  }
  openImg(imgURL) {
    const blob: Blob = this.b64toBlob(imgURL);
    const objectUrl: string = URL.createObjectURL(blob);
    window.open(objectUrl);
  }
  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }
  getReport(excelMenuId: string) {
    this.isLoading = true;
    let excelMenu = this.reportList.find(r => r.ExcelMemuID == excelMenuId);
    this.reportsService.getReport(excelMenu).subscribe((data) => {
      if (data != null) {

        let displayedColumns = [];
        if (data.length > 0) {
          Object.keys(data[0]).forEach(key => {
            displayedColumns.push(key);
          });
          this.widthOfReportTable = `${displayedColumns.length * 141}px`;
        }
        else {
          this.widthOfReportTable = "100%";
        }
        this.dataSource = new MatTableDataSource(data);
        this.displayedColumns = displayedColumns;
        this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.applySearch;
      }
      this.isLoading = false;
    });
  }
  search(value) {
    this.dataSource.filter = JSON.stringify(this.filterEntity);

  }
  displaySearch() {
    this.isDisplaySearch = !this.isDisplaySearch;
    if (this.isDisplaySearch === false) {
      this.onSearchClear();
    }
  }
  onSearchClear() {
    this.filterEntity = {};
  }

  applySearch(data: any, filter: string) {
    let filterEntity = JSON.parse(filter)
    let result = true;
    Object.keys(filterEntity).forEach(key => {
      if (filterEntity[key] != null && filterEntity[key].trim() != '') {
        if (key === 'value') {
          if (!JSON.stringify(data).trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
            result = false;
          }
        }
        else {
          if (!data[key].toString().trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
            result = false;
          }
        }
      }
    });
    return result;
  }
  getReportExcelMenu() {
    return this.reportList.find(r => r.ExcelMemuID == this.currentReport);
  }
  openINVOICE(invoice) {
    this.fileService.downloadFile(invoice).then((data) => {
      if (data == null) {
        this._snackBar.open("הודעה", "לא נמצא קובץ", {
          duration: 2000,
        });
      } else {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
    });

  }
  export() {
    let filteredColumn = this.filterColumn();
    this.exportService.exportAsExcelFile(filteredColumn, this.getReportExcelMenu().Description);
  }

  filterColumn() {
    if (this.dataSource.filteredData.length > 0) {
      this.dataSource.filteredData.forEach(row => {
        Object.keys(row).forEach(key => {
          if (!this.displayedColumns.includes(key)) {
            delete row[key];
          }
        })
      });
    }
    return this.dataSource.filteredData;
  }
  getWidth() {
    return "8000px"
  }
  openDialog(component, data, width, height) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data
    dialogConfig.autoFocus = true;
    dialogConfig.width = width;
    dialogConfig.height = height;
    dialogConfig.position = {
    };
    {
      dialogConfig.disableClose = true;
    }
    return this.dialog.open(component, dialogConfig);
  }
  sendMail() {
    this.openDialog(SendEmailComponent, this.getReportExcelMenu().Description, "50%", "50%").afterClosed().subscribe(data => {
      this.disabledSendMail = true;
      if (data != undefined && data.to.length > 0) {
        let filteredColumn = this.filterColumn();
        let excelMenu = this.getReportExcelMenu();
        let sendReportMail = {
          viewName: excelMenu.Description,
          reportName: excelMenu.ReportName,
          exportType: excelMenu.Type == exportType.csv ? exportType.csv : exportType.none,
          fileData: filteredColumn,
          to: data.to,
        }
        this.reportsService.sendMail(sendReportMail).subscribe(result => {
          this.disabledSendMail = false;
          this._snackBar.open("Message", "The email has been sent", {
            duration: 2000,
          });
        }, (error) => {
          this.disabledSendMail = false;
          this._snackBar.open("System error","Please contact the system administrator", {
            duration: 2000,
          });
        });
      }
      else {
        this.disabledSendMail = false;
      }
    });
    // let addEmailAddressPopUp = this._snackBar.openFromComponent(AddEmaillSnackBarComponent, {
    //   data: `הוסף כתובות`,
    // });
    // addEmailAddressPopUp.onAction().subscribe(() => {
    //   this.disabledSendMail = true;
    //   if (addEmailAddressPopUp.instance.addressList != undefined && addEmailAddressPopUp.instance.addressList.length > 0) {
    //     let filteredColumn = this.filterColumn();
    //     let excelMenu = this.getReportExcelMenu();
    //     let sendReportMail = {
    //       viewName: excelMenu.Description,
    //       reportName: excelMenu.ReportName,
    //       exportType: excelMenu.Type == exportType.csv ? exportType.csv : exportType.none,
    //       fileData: filteredColumn,
    //       to: addEmailAddressPopUp.instance.addressList,
    //     }
    //     this.reportsService.sendMail(sendReportMail).subscribe(result => {
    //       this.disabledSendMail = false;
    //       this._snackBar.open("הודעה", "המייל נשלח", {
    //         duration: 2000,
    //       });
    //     }, (error) => {
    //       this.disabledSendMail = false;
    //       this._snackBar.open("שגיאת מערכת", error.message, {
    //         duration: 2000,
    //       });
    //     });
    //   }
    //   else {
    //     this.disabledSendMail = false;
    //   }
    // });
    // addEmailAddressPopUp.afterDismissed().subscribe(() => {
    //   this.disabledSendMail = false;
    // });
  }
}
