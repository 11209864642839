import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import {Observable} from 'rxjs';

@Injectable()
export class SigmentsService {
  sigments: any;
  promise: any;
  header: HttpHeaders;

  sigmentsCategory;
  resx: any = null;
  SigmentsStatus;
  orgName = null;
  stageOneData: any = null;
  dataSet: any = {};
  //EventEmitter
  @Output() change: EventEmitter<any> = new EventEmitter();
  IsPowerUser: boolean;

  /**
   * CTOR
   * @param http
   * @param route
   * @param router
   * @param authService
   * @param settingSrv
   */
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  getLanguageList(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/User/GetLanguageList", {
          headers: this.header,
        })
        .subscribe((langList) => {
          resolve(langList);
        });
    });
    return promise;
  }

  getOrgForUser(): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    //let promise = new Promise((resolve, reject) => {
     return this.http
        .get(environment.GetApiRootUrl() + "api/app/User/Org", {
          headers: this.header,
        });
    //     .subscribe((data) => {
    //       this.orgName = data;
    //       this.change.emit(this.orgName);
    //       resolve(this.orgName);
    //     });
    // });
   // return promise;
  }

  getPdf(_url): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(_url, { headers: this.header, responseType: "blob" as "json" })
        .subscribe((_pdf) => {
          resolve(_pdf);
        });
    });
    return promise;
  }

  getExcel(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "api/files/data/export", {
          headers: this.header,
          responseType: "blob",
        })
        .subscribe((_file) => {
          resolve(_file);
        });
    });
    return promise;
  }

  getLastDoc(linkPOD): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "api/files/generete/" + linkPOD, {
          headers: this.header,
          responseType: "blob" as "json",
        })
        .subscribe((_png) => {
          resolve(_png);
        });
    });
    return promise;
  }

  getIsPoweUser(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/User/IsPowerUser", {
          headers: this.header,
        })
        .subscribe((IsPowerUser) => {
          this.IsPowerUser = IsPowerUser as boolean;
          resolve(this.IsPowerUser);
        });
    });
    return promise;
  }

  setPowerUserOrg(orgName): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(
          environment.GetApiRootUrl() +
          "/api/app/User/SetSelectedOrg/" +
          orgName,
          { headers: this.header }
        )
        .subscribe((success) => {
          this.stageOneData = null;
          this.sigmentsCategory = null;
          resolve(success as boolean);
        });
    });
    return promise;
  }

  getOrgList(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/User/GetOrgList", {
          headers: this.header,
        })
        .subscribe((orgList) => {
          resolve(orgList);
        });
    });
    return promise;
  }

  getSearchResult(searchRequest: string) {
    var reqHeader = this.authService.GetHeaderToken();
    reqHeader.set("Content-Type", "application/json");
    var data = {
      SearchRequest: searchRequest
    };
    let promise = new Promise(resolve => {
      this.http
        .post(environment.GetApiRootUrl() + "api/app/search", data, {
          headers: reqHeader
        })
        .toPromise()
        .then(data => {
          resolve(JSON.parse("" + data));
        })
        .catch(err => {
          resolve(err.error.Message);
        });
    });
    return promise;
  }
  getSigmentsCategory(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/list/sigments_category", {
          headers: this.header,
        })
        .subscribe((data) => {
          this.dataSet = {};
          this.sigmentsCategory = JSON.parse("" + data);
          let notActive = ["CR", "FN", "IN"];
          this.sigmentsCategory = this.sigmentsCategory.filter(r => notActive.indexOf(r.Name) == -1)
          resolve(this.sigmentsCategory);
        });
    });
    return promise;
  }

  getSigmentsStatus(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/list/sigments_status", {
          headers: this.header,
        })
        .subscribe((data) => {
          this.SigmentsStatus = JSON.parse("" + data);
          resolve(this.SigmentsStatus);
        });
    });
    return promise;
  }

  getStageOneData(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/list/sigments", {
          headers: this.header,
        })
        .subscribe((data) => {
          this.stageOneData = JSON.parse("" + data);
          resolve(this.stageOneData);
        });
    });
    return promise;
  }
  getDataSet(sigmentName): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/sigments/" + sigmentName, {
          headers: this.header,
        })
        .subscribe((data) => {
          this.dataSet[sigmentName] = JSON.parse("" + data)
          resolve(this.dataSet[sigmentName]);
        });
    });
    return promise;
  }
  getStageTwoData(
    activeSig: string,
    size: number,
    index: number,
    order: string,
    orderBy: string,
    isDest: boolean,
  ) {
    var reqHeader = this.authService.GetHeaderToken();
    reqHeader.set("Content-Type", "application/json");
    var data = {
      ActiveSigment: activeSig,
      Size: size,
      Index: index,
      Order: order,
      OrderBy: orderBy,
      IsDest: isDest,
    };
    return this.http
      .post(environment.GetApiRootUrl() + "api/app/stagetwo", data, {
        headers: reqHeader,
      })
    // let promise = new Promise((resolve) => {
    //   this.http
    //     .post(environment.GetApiRootUrl() + "api/app/stagetwo", data, {
    //       headers: reqHeader,
    //     })
    //     .toPromise()
    //     .then((data) => {
    //       resolve(JSON.parse("" + data));
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // });
    // return promise;
  }

  getStageTwoMovmentsData(size: number, index: number, order, orderBy, isDest) {
    this.header = this.authService.GetHeaderToken();
    this.header.set("Content-Type", "application/json");
    var data = {
      Size: size,
      Index: index,
      Order: order == '' ? 'null' : order,
      OrderBy: orderBy,
      IsDest: isDest,
    };
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.GetApiRootUrl()}/api/app/StageTwo/logistic/movments`, data,
          { headers: this.header }
        )
        .subscribe((data) => {
          resolve(JSON.parse("" + data));
        });
    });
    return promise;
  }

  getStageTwoInventoryData(size: number, index: number, order, orderBy, isDest) {
    this.header = this.authService.GetHeaderToken();
    this.header.set("Content-Type", "application/json");
    var data = {
      Size: size,
      Index: index,
      Order: order == '' ? 'null' : order,
      OrderBy: orderBy,
      IsDest: isDest,
    };
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(`${environment.GetApiRootUrl()}/api/app/StageTwo/logistic/inventory`, data, { headers: this.header })
        .subscribe((data) => {
          resolve(JSON.parse("" + data));
        });
    });
    return promise;
  }

  GetResx(): any {
    // if (!this.orgName) {
    //   this.getOrgForUser();
    // }

    var _currentLang = localStorage.getItem("selectedLang");
    var currentLang = 0;
    if (!_currentLang) {
      currentLang = environment.currentLang;
    } else {
      currentLang = parseInt(_currentLang);
    }
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      if (!this.resx) {
        this.http
          .get(
            environment.GetApiRootUrl() + "/api/app/list/resx/" + currentLang,
            { headers: this.header }
          )
          .subscribe((data) => {
            this.resx = JSON.parse("" + data);
            resolve(this.resx);
          });
      } else {
        resolve(this.resx);
      }
    });
    return promise;
  }

  getStageFourData(
    activeSig: string,
    size: number,
    index: number,
    sku,
    po,
    hbl = "0",
    OHSRLN = null
  ) {
    var reqHeader = this.authService.GetHeaderToken();
    reqHeader.set("Content-Type", "application/json");
    var data = {
      ActiveSigment: activeSig,
      Size: size,
      Index: index,
      Sku: sku,
      PO: po,
      Hbl: hbl,
      OHSRLN: OHSRLN
    };
    let promise = new Promise((resolve) => {
      this.http
        .post(environment.GetApiRootUrl() + "api/app/stagefour", data, {
          headers: reqHeader,
        })
        .toPromise()
        .then((data) => {
          resolve(JSON.parse("" + data));
        })
        .catch((err) => {
          if (err.error) {
            resolve(err.error.Message);
          }
        });
    });
    return promise;
  }

  getFritzGraphData(): any {
    this.header = this.authService.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "/api/app/graphData", {
          headers: this.header,
        })
        .subscribe((data) => {
          this.dataSet = JSON.parse("" + data)
          resolve(this.dataSet);
        });
    });
    return promise;
  }
}
