import { Component, OnInit, OnDestroy } from '@angular/core';
// import { map } from 'rxjs/operators';
// import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { SigmentsService } from 'src/app/services/sigments.service';
import { filter, takeUntil, pairwise, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FritzGraphComponent } from '../fritz-graph/fritz-graph.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ModalService } from 'src/app/_modal';

declare let ga: Function;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public destroyed = new Subject<any>();
  /** Based on the screen size, switch from standard to one column per row */
  categoryList: any[];
  Resx: any={};
  data: any = {
    LOGISTIC: { isActive: false, DataRows: [] },
    AI: { isActive: false, DataRows: [] },
    CB: { isActive: false },
    AE: { isActive: false, DataRows: [] },
    DELIVERY: { isActive: false, DataRows: [] },
    OI: { isActive: false, DataRows: [] },
    OE: { isActive: false, DataRows: [] },
    CR: { isActive: false },
    FN: { isActive: false },
    VS: { isActive: false, DataRows: [] },
    IN: { isActive: false },
    FOLLOW: { isActive: false, DataRows: [] },
  };
  dialog: any;
  // cards =  this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  //   map(({ matches }) => {
  //     //if (matches) {
  //       return [
  //         {name:"AE",title: 'Air Export', cols: 1, rows: 1 },
  //         {name:"AI", title: 'Air Import', cols: 1, rows: 1 },
  //         {name:"OI", title: 'Ocean Import', cols: 1, rows: 1 },
  //         {name:"OE", title: 'Ocean Export', cols: 1, rows: 1 },
  //         {name:"DELIVERY", title: 'Inland transportation', cols: 1, rows: 1 },
  //         {name:"VS", title: 'Exhibition & Drops', cols: 1, rows: 1 },
  //         {name:"FOLLOW", title: 'PO Follow Up', cols: 1, rows: 1 },
  //         {name:"LOGISTIC", title: 'Logistics Sites', cols: 1, rows: 1 }
  //       ];
  //     //}

  //     // return [
  //     //   {name:"AE",title: 'Air Export', cols: 1, rows: 1 },
  //     //   {name:"AI", title: 'Air Import', cols: 1, rows: 1 },
  //     //   {name:"OI", title: 'Ocean Import', cols: 1, rows: 1 },
  //     //   {name:"OE", title: 'Ocean Export', cols: 1, rows: 1 },
  //     //   {name:"DELIVERY", title: 'Inland transportation', cols: 1, rows: 1 },
  //     //   {name:"VS", title: 'Exhibition & Drops', cols: 1, rows: 1 },
  //     //   {name:"FOLLOW", title: 'PO Follow Up', cols: 1, rows: 1 },
  //     //   {name:"LOGISTIC", title: 'Logistics Sites', cols: 1, rows: 1 }
  //     // ];
  //   })
  // );

  constructor(public modalService: ModalService,private sigmentsSrv: SigmentsService, private matDialog: MatDialog, private router: Router, public googleAnalyticsService: GoogleAnalyticsService) {
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });

    
  }
  SendLogEvent() {
    //We call the event emmiter function from our service and pass in the details
    this.googleAnalyticsService.eventEmitter("View information", "Dashboard", "Step 1",  1);
  }

  navigateToSigment(sigmentName) {
    this.router.navigate(["/home/" + sigmentName]);
  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
  openLogisticDialog(){
    this.modalService.open("logisticModal");
  }
 closeLogisticDialog(){
    this.modalService.close("logisticModal");
  }
  ngOnInit() {
    // this.router.events.pipe(
    //   filter((event: RouterEvent) => event instanceof NavigationEnd),
    //   takeUntil(this.destroyed)
    // ).subscribe((event: RouterEvent) => {
    //   if ((event as NavigationEnd).url === (event as NavigationEnd).urlAfterRedirects) {
    //     this.getDataFromApi(true);
    //   }
    // });
    this.getDataFromApi(false);

    // if (this.isFritz())
    //   this.onpeFritzGraph();

    this.SendLogEvent();
  }
  getDataFromApi(isForceLoad): void {
    if (this.sigmentsSrv.stageOneData != null && !isForceLoad) {
      this.data = this.sigmentsSrv.stageOneData;
    }
    else {
      this.sigmentsSrv.getStageOneData().then((data) => {
        this.data = data;

      });
    }
    if (this.sigmentsSrv.sigmentsCategory != null && !isForceLoad) {
      this.categoryList = this.sigmentsSrv.sigmentsCategory;

    } else {
      this.sigmentsSrv.getSigmentsCategory().then((categoryJson) => {
        this.categoryList = categoryJson;
      });
    }
  }

  isFritz(): boolean {
    let result = false;

    var user = localStorage.getItem("username");
    var endMail = user.split('@')[1];

    if (endMail == "fritz.co.il")
      result = true;

    return result;
  }

  onpeFritzGraph() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = "";
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "95%";
    dialogConfig.position = {
   
    };
    {
      dialogConfig.disableClose = true;
    }
    this.matDialog.open(FritzGraphComponent, dialogConfig);

    /*  let dis = this.dialog.open(HandleCustomerDetailsComponent, dialogConfig);
      dis.afterClosed().subscribe(data => {
        if (data != undefined) {
          if (data==true)
          this.getData();
        }
  
      })*/


  }

}
