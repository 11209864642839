import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartType, ChartDataSets } from 'chart.js';
import { Label, MultiDataSet, SingleDataSet, BaseChartDirective } from 'ng2-charts';
import { SigmentsService } from 'src/app/services/sigments.service';

@Component({
  selector: 'app-sigment-chart',
  templateUrl: './sigment-chart.component.html',
  styleUrls: ['./sigment-chart.component.scss']
})
export class SigmentChartComponent implements OnInit {
  showChart = false;
  @Input() sigmentName
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  // public doughnutChartLabels = ['פתוח', 'תיקים'];
  // public doughnutChartData = [0, 150];
  // public doughnutChartType = 'doughnut';
  public open = 0;
  public total = 0;
  public precent=0;
  // public chartColors: any[] = [
  //   { 
  //     backgroundColor:["#38788C", "#c107f5"] 
  //   }];
  constructor(private sigmentservice: SigmentsService) { }

  ngOnInit() {
    if (this.sigmentservice.dataSet[this.sigmentName] != null) {
      this.open = this.sigmentservice.dataSet[this.sigmentName].OpenedRecords;
      this.total = this.sigmentservice.dataSet[this.sigmentName].TotalCount
      this.precent=this.open/this.total*100
      this.showChart = true;
    }
    else {
      this.sigmentservice.getDataSet(this.sigmentName).then(data => {
        if (data != null) {
          this.open = data.OpenedRecords;
          this.total = data.TotalCount
          this.precent=this.open/this.total*100
          this.showChart = true;
        }
      });
    }
  }
}
