import { Component, OnInit, ViewChild } from '@angular/core';
import { ArchiveService } from 'src/app/services/archive.service';
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from "@angular/material";
import { Observable, Subscription } from 'rxjs';
import { SigmentsService } from 'src/app/services/sigments.service';
import { ShowDocumentsComponent } from './show-documents/show-documents.component';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  docsList: any = [{ desc: "Freight & Customs" }, { desc: "Logisticts & WH" }, { desc: "Critilog" }];
  isLoading: boolean;
  widthOfReportTable = "100%";
  dataSource: MatTableDataSource<any>;
  displayedColumns = [];
  displayedColumnsSearch=[];
  filterEntity: any = {};
  disableOpenDoc: boolean = false;
  isDisplaySearch=false;
  private subscriptions: Subscription = new Subscription();
  noContent: boolean = false;
  constructor(private archiveService: ArchiveService, private sigmentsSrv: SigmentsService, private dialog: MatDialog, private fileService: FileService, private _snackBar: MatSnackBar) { }
  displaySearch() {
    this.isDisplaySearch = !this.isDisplaySearch;
    if (this.isDisplaySearch === false) {
      this.onSearchClear();
    }
  }
  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  getDoc(docType: string) {
    this.noContent = false;
    this.dataSource = new MatTableDataSource(null);
    this.displayedColumns = [];
    this.displayedColumnsSearch = [];
    this.widthOfReportTable = "100%";
    this.dataSource.filterPredicate = this.applySearch;
    this.isLoading = true;
    if (docType == "Freight & Customs") {
      this.archiveService.getAmitalInvoices().subscribe((data) => {
        if (data != null) {
          let displayedColumns = [];
          if (data.length > 0) {
            Object.keys(data[0]).forEach(key => {
              displayedColumns.push(key);
            });
            this.widthOfReportTable = `${displayedColumns.length * 141}px`;
          }
          this.dataSource = new MatTableDataSource(data);
          this.displayedColumns= displayedColumns;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.filterPredicate = this.applySearch;
          this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
        }
        else {
          this.noContent = true;
        }
        this.isLoading = false;
      });
    }
    else {
      if (docType == "Logisticts & WH") {
        this.archiveService.getInvoices().subscribe((data) => {
          if (data != null) {
            let displayedColumns = [];
            if (data.length > 0) {
              Object.keys(data[0]).forEach(key => {
                displayedColumns.push(key);
              });
              this.widthOfReportTable = `${displayedColumns.length * 141}px`;
            }
            this.dataSource = new MatTableDataSource(data);
            this.displayedColumns = displayedColumns;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.applySearch;
            this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
          }
          else {
            this.noContent = true;
          }
          this.isLoading = false;
        });
      }
      else {
        this.archiveService.getInvoicesList1().subscribe((data) => {
          if (data != null) {
            let displayedColumns = [];
            if (data.length > 0) {
              Object.keys(data[0]).forEach(key => {
                displayedColumns.push(key);
              });
              this.widthOfReportTable = `${displayedColumns.length * 141}px`;
            }
            this.dataSource = new MatTableDataSource(data);
            this.displayedColumns = displayedColumns;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.applySearch;
            this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
          }
          else {
            this.noContent = true;
          }
          this.isLoading = false;
        });
      }
    }
  }
//   displayColumn(disCol){
//     return disCol.replace("Show_", '');
// }
  // isLinkIDColumn(disCol) {
  //   if (disCol.match(/Show.*/)) {
  //     return true;
  //   }
  //   return false;
  // }
  search(value) {
    this.dataSource.filter = JSON.stringify(this.filterEntity);
  }
 
  onSearchClear() {
    this.filterEntity = '';
    this.dataSource.filter = ''
  }
  applySearch(data: any, filter: string) {
    let filterEntity = JSON.parse(filter)
    let result = true;
    Object.keys(filterEntity).forEach(key => {
      if (filterEntity[key] != null && filterEntity[key].trim() != '') {
        if (key === 'value') {
          if (!JSON.stringify(data).trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
            result = false;
          }
        }
        else {
          if (!data[key].toString().trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
            result = false;
          }
        }
      }
    });
    return result;
  }
  openINVOICE(invoice) {
    invoice = invoice.substr(0, invoice.indexOf('.pdf'));
    this.fileService.downloadFilePriorityInvoices(invoice).then((data) => {
      if (data == null) {
        this._snackBar.open("הודעה", "לא נמצא קובץ", {
          duration: 2000,
        });
      } else {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
    });

  }


  openDoc(disCol, element) {
    this.disableOpenDoc = true;
    switch (element.SIGMENT) {
      case "Air Import":
        this.getDataFromApi("AI", element.TIKID, disCol);
        break;
      case "Air Export":
        this.getDataFromApi("AE", element.TIKID, disCol);
        break;
      case "Ocean Import":
        this.getDataFromApi("OI", element.TIKID, disCol);
        break;
      case "Ocean Export":
        this.getDataFromApi("OE", element.TIKID, disCol);
        break;
    }
  }
  getDataFromApi(sigment, tickId, disCol) {
    this.archiveService.getDocs(sigment, tickId).subscribe((data) => {
      if (disCol == "Invoices") {
        switch (sigment) {
          case "AI":
          case "OI":
            data = data.filter(d => d.DocType == "CINV");
            break;
          case "AE":
            data = data.filter(d => d.DocType == "EINV");
            break;
          case "OE":
            data = data.filter(d => d.DocType == "OINV");
            break;
        }
      }
      this.openDetailsdialog(data);
    });
  }
  openDetailsdialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "55%";
    dialogConfig.height = "60%";
    dialogConfig.position = {
    };
    {
      dialogConfig.disableClose = true;
    }
    this.subscriptions.add(this.dialog.open(ShowDocumentsComponent, dialogConfig).afterClosed().subscribe(() => {
      this.afterAllClosed();
    }));

  }
  afterAllClosed() {
    this.disableOpenDoc = false;
  }
}
