import { Injectable } from '@angular/core';

declare let gtag: Function; // Declare ga as a function


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
      eventAction=`${eventAction}|${localStorage.getItem("username")}|${localStorage.getItem("orgName")}|${localStorage.getItem("orgCrm")}`;
     console.log(eventAction);
      gtag('event',eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'value': eventValue
      });
  }
}
