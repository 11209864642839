import { Component, OnInit, HostBinding } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { SigmentsService } from "src/app/services/sigments.service";
import { MatSnackBar } from "@angular/material";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  type = "password";
  show = false;
  showError = false;
  errMsg: string;
  isFirstLogin = false;
  username = "";
  password = "";
isSubmit=false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public googleAnalyticsService: GoogleAnalyticsService
  ) { }

  SendLogEvent() {
    //We call the event emmiter function from our service and pass in the details
    this.googleAnalyticsService.eventEmitter("User sign in","Login", "Login", 1);
  }
  OnSubmit(userame, password) {
    this.isSubmit=true;
    this.errMsg = "";
    this.authService.UserAuthentication(userame, password).then((err) => {

      if (err != "incorrect") {
        //set is first login
        this.authService.GetIsFirstLogin().then((isFirstLogin) => {  
          this.isSubmit=false;
          this.isFirstLogin = isFirstLogin;
          this.SendLogEvent();
          this.router.navigate(["/home/dashboard"]);

        });
      } else {
        this.isSubmit=false;
        this.showError = true;
        this.snackBar.open(" בדוק שם משתמש וסיסמא", "שגיאת התחברות", {
          duration: 2000,
        });
        this.errMsg = "Check your name or password";
      }
    });
  }
  ngOnInit() { }

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }
}
