import { Component, OnInit } from '@angular/core';
import { SigmentsService } from "src/app/services/sigments.service";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { StepFourComponent } from '../step-four/step-four.component';
import { StepFourLogisticsComponent } from '../step-four/logistics/step-four-logistics.component';
import { stubFalse } from 'lodash';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchRequest: any;
  sigmentsDataArr: any;
  isSearchResultHasData: boolean =false;
  isSearchResultHasDataMobile: boolean;
  showSpinner = false;
  noData = true;
  Resx: any[];
  stepFourData = {};
  constructor(private sigmentsSrv: SigmentsService, private router: Router, private dialog: MatDialog,) { }

  SubmitSearch(searchRequest) {
    this.showSpinner = true;
    this.sigmentsSrv.getSearchResult(searchRequest).then(data => {
      this.sigmentsDataArr = data;
      if (this.sigmentsDataArr != null) {
        this.isSearchResultHasData = this.sigmentsDataArr.length > 0;
      }
      this.showSpinner = false;
    });
  }
  OpenSigment(sig) {
    if (sig.Sigment === "LOGISTIC" || sig.Sigment === "FOLLOW") {
      this.stepFourData = {
        activity: sig.Sigment,
        po: sig.Po,
        OHSRLN: sig.OHSRLN
      };
    }
    else if (sig.Sigment === "OE") {
      this.stepFourData = {
        activity: sig.Sigment,
        tikId: sig.Tik,
        hbl: sig.HBL
      };

    }
    else {
      this.stepFourData = {
        activity: sig.Sigment,
        tikId: sig.Tik,
      };
    }
    this.openDetailsdialog(this.stepFourData);
    this.Clear();
  }

  openDetailsdialog(stepFourData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = stepFourData;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "90%";
    dialogConfig.position = {
    };
    {
      dialogConfig.disableClose = true;
    }
    if (stepFourData.activity == "LOGISTIC") {
      this.dialog.open(StepFourLogisticsComponent, dialogConfig);
    }
    else {
      this.dialog.open(StepFourComponent, dialogConfig);
    }
  }


  SubmitSearchMobile(searchRequest) {
    this.showSpinner = true;
    this.sigmentsSrv.getSearchResult(searchRequest).then(data => {
      this.sigmentsDataArr = data;
      this.isSearchResultHasDataMobile = this.sigmentsDataArr.length > 0;
      this.showSpinner = false;
    });
  }

  submitForm() {
    this.noData = false;
  }

  ngOnInit() {
    this.sigmentsSrv.GetResx().then(resx => {
      this.Resx = resx;
    });
  }
  Clear() {
    this.isSearchResultHasDataMobile = false;
    this.isSearchResultHasData = false;
    this.searchRequest = null;
    this.noData = true;
  }
}
