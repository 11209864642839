import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Input,
  ElementRef,
  Inject,
} from "@angular/core";
import {
  MatSort,
  MatTableDataSource,
  MatPaginator,
  MatTable,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { SigmentsService } from "src/app/services/sigments.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { merge } from 'lodash';
import { fromEvent } from 'rxjs';
import { OrgService } from 'src/app/services/org.service';

@Component({
  selector: "app-step-four-logistics",
  templateUrl: "./step-four-logistics.component.html",
  styleUrls: ["./step-four-logistics.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
    trigger("openClose", [
      // ...
      state(
        "open",
        style({
          // height: "400px",
          opacity: 1,
          backgroundColor: "white",
        })
      ),
      state(
        "closed",
        style({
          height: "0px",
          opacity: 1,
          backgroundColor: "white",
        })
      ),
      transition("open => closed", [animate("0.5s")]),
      transition("closed => open", [animate("0.5s")]),
      transition("* => closed", [animate("0s")]),
      transition("* => open", [animate("0.5s")]),
      transition("open <=> closed", [animate("0.5s")]),
      transition("* => open", [animate("0.5s", style({ opacity: "*" }))]),
      transition("* => *", [animate("0s")]),
    ]),
  ],
})
export class StepFourLogisticsComponent implements OnInit {
  @ViewChild("paginator", { static: false }) paginator: MatPaginator;
  //@ViewChildren("innerTables") innerTables: QueryList<MatTable<any>>;
  @ViewChild("inPaginator", { static: false }) inPaginator: MatPaginator;
  @ViewChild("inventorySort", { static: true }) inSort: MatSort;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //@ViewChildren("innerSort") innerSort: QueryList<MatSort>;
  @ViewChild('Filter', { static: false }) filter: ElementRef;
  dataSource: MatTableDataSource<any>;
  tableData: MatTableDataSource<any>;

  data: any[] = [];
  expandedElement: any | null;
  displayedColumns = ["Details", "Count", "Createload", "Putaway", "Shipload"];
  //sigments category list
  categoryList: any[];
  columnsToDisplay = [];
  sigments: any = { data: [], total_count: 0 };
  orderBy = "default";
  isDest = true;
  orderByinventory = "default";
  isDestinventory = true;
  activeSigment: string = "LOGISTIC";
  size: number = 1000;
  totalMovment: number;
  indexMovment: number = 1;
  sigmentDescription: string;
  order: string = "";
  orderInventory: string = "";
  Resx: any = { NOACTIVITIES: "", ChooseCategory: "" };
  isLogOpen: boolean;
  isLoading = false;
  filterValue = "";
  tempData: any;
  totalInventory: any;
  columnsInventoryToDisplay: any;
  dataInventorySource: MatTableDataSource<unknown>;
  public tabIndex:number = 0;
  constructor(
    private sigmentsSrv: SigmentsService,
    private dialogRef: MatDialogRef<StepFourLogisticsComponent>,
    private orgService: OrgService,
    @Inject(MAT_DIALOG_DATA) public myData: any
  ) {
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.orderBy = this.sort.active;
      this.isDest = this.sort.direction == "asc" ? true : false;
      this.paginator.pageIndex = 0;
      this.getMovmentsDataFromApi(this.paginator.pageIndex, this.paginator.pageSize);
    });

    this.paginator.page
      .pipe(
        tap(() => this.getMovmentsDataFromApi(this.paginator.pageIndex, this.paginator.pageSize) )
      )
      .subscribe();
    //

    this.inSort.sortChange.subscribe(() => {
      this.orderByinventory = this.inSort.active;
      this.isDestinventory = this.inSort.direction == "asc" ? true : false;
      this.inPaginator.pageIndex = 0
      this.getInventoryDataFromApi(this.inPaginator.pageIndex, this.inPaginator.pageSize);
    });

    this.inPaginator.page
      .pipe(
        tap(() => this.getMovmentsDataFromApi(this.inPaginator.pageIndex, this.inPaginator.pageSize))
      )
      .subscribe();
    //
  }
  ngOnInit() {
    this.isLoading = true;
    //this.getCharts();
    this.isLogOpen = false;
    this.getLogData(0, 15);
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
  }
  getInventoryDataFromApi(index, size): any {
    this.sigmentsSrv
      .getStageTwoInventoryData(size, index + 1, this.orderInventory, this.orderByinventory, this.isDestinventory)
      .then((data:any) => {
        if (data) {
        this.sigments.InventoryTbl = data.InventoryTbl;
        this.dataInventorySource = new MatTableDataSource(this.sigments.InventoryTbl);
        this.totalInventory = data.TotalRows_Inventory;
        this.columnsInventoryToDisplay =data.Headers_Inventory;
        this.inPaginator.length = this.totalInventory;
        this.isLoading = false;
        }
      });
  }

  getMovmentsDataFromApi(index, size): any {
    this.sigmentsSrv
      .getStageTwoMovmentsData(size, index + 1, this.order, this.orderBy, this.isDest)
      .then((data:any) => {
        if (data) {
          this.sigments.MovmentTbl = data.MovmentTbl;
          this.totalMovment = data.TotalRows_Movment;
          this.columnsToDisplay = data.Headers_Movment;

          const index = this.columnsToDisplay.indexOf("Activities", 0);
          if (index > -1) {
            this.columnsToDisplay.splice(index, 1);
          }
          this.dataSource = new MatTableDataSource(this.sigments.MovmentTbl);
          this.paginator.length = this.totalMovment
          this.isLoading = false;
        }
      });
  }
  getLogData(pageIndex, pageSize) {
    this.sigmentsSrv
      .getStageTwoData("LOGISTIC", pageSize, pageIndex + 1, this.order != "" ? this.order : null, this.orderBy, this.isDest)
      .subscribe((data) => {
        this.sigments = JSON.parse("" + data);
        if (this.sigments) {
          this.totalMovment = this.sigments.TotalRows_Movment;
          this.columnsToDisplay = this.sigments.Headers_Movment;

          const index = this.columnsToDisplay.indexOf("Activities", 0);
          if (index > -1) {
            this.columnsToDisplay.splice(index, 1);
          }
          this.dataSource = new MatTableDataSource(this.sigments.MovmentTbl);
          this.paginator.length = this.sigments.TotalRows_Movment

          this.totalInventory = this.sigments.TotalRows_Inventory;
          this.columnsInventoryToDisplay = this.sigments.Headers_Inventory;

          this.dataInventorySource = new MatTableDataSource(
            this.sigments.InventoryTbl
          );
          this.inPaginator.length = this.sigments.TotalRows_Inventory;
        }
        this.isLoading = false;
        // this.dataSource = this.sigments;

        //DO NOT DELETE NEXT CODE =>
        // if(this.sigments && this.activeSigment == "DELIVERY"){
        //   for (var i = 0; i < this.sigments.MovmentTbl.length; i++) {
        //     this.sigmentsSrv.getLastDoc(this.sigments.MovmentTbl[i].LinkPOD).then(data => {
        //       if(data != null){
        //         this.sigments.MovmentTbl[i].hasFiles = true;
        //       }
        //     });
        //   }
        // }
      });

    // this.sigmentsSrv.getSigmentsCategory().then((categoryJson) => {
    //   for (var i = 0; i < categoryJson.length; i++) {
    //     var sig = categoryJson[i];
    //     if (sig.Name == this.activeSigment) {
    //       this.sigmentDescription = sig.Description;
    //     }
    //   }
    // });
  }

  applyFilter(value: string, isMovments) {
    if (isMovments) {
      this.order = value.trim();
      this.paginator.pageIndex = 0;
      this.getMovmentsDataFromApi(this.paginator.pageIndex, this.paginator.pageSize);

    } else {
      this.orderInventory = value.trim();
      this.inPaginator.pageIndex = 0;
      this.getInventoryDataFromApi(this.inPaginator.pageIndex, this.inPaginator.pageSize);
    }
  }
  toggleRow(element: any) {
    element
      ? (this.expandedElement =
        this.expandedElement === element ? null : element)
      : null;
    // this.cd.detectChanges();
    // this.innerTables.forEach(
    //   (table, index) =>
    //     ((table.dataSource as MatTableDataSource< any>).sort = this.innerSort.toArray()[index])
    // );
  }
  toggle() {
    this.isLogOpen = !this.isLogOpen;
    this.getCharts();
  }
  getCharts() {
    this.sigmentsSrv.getStageOneData().then((data) => {
      this.tempData = data["LOGISTIC"];
      this.tableData = new MatTableDataSource<any>(this.tempData);
      this.sigmentsSrv.getSigmentsCategory().then((categoryJson) => {
        this.categoryList = categoryJson;
      });
    });
  }
  close() {
    this.dialogRef.close();
  }
}