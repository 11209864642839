import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BaseChartDirective } from 'angular-bootstrap-md';
import { SigmentsService } from 'src/app/services/sigments.service';
import *  as  data from 'src/assets/GraphOption.json';
@Component({
  selector: 'app-fritz-graph',
  templateUrl: './fritz-graph.component.html',
  styleUrls: ['./fritz-graph.component.scss']
})
export class FritzGraphComponent implements OnInit {

  showChart = false;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  GraphTables = {
    AERev: [],
    AIRev: [],
    BrokerageIRev: [],
    CLRRev: [],
    ExhibitionERev: [],
    FritzTradeRev: [],
    LevanonKittingRev: [],
    LogisticRev: [],
    MoviliDrorRev: [],
    OERev: [],
    OIRev: []
  }
  relevantTable: string[] = []


  constructor(private dialogRef: MatDialogRef<FritzGraphComponent>,
    @Inject(MAT_DIALOG_DATA) public myData: any,
    private sigmentservice: SigmentsService) { }

  ngOnInit(): void {
    this.sigmentservice.getFritzGraphData().then(response => {
      if (response != null) {
        this.GraphTables = response['Result'];
        this.getData();
      }
    },
      error => {  }
    );
  }
  getData() {
    (data as any).default.forEach(element => {
      if (this.GraphTables[element.name] != undefined && this.GraphTables.AERev['Data'].length > 0) {
        this.relevantTable.push(element.name);
      }
    });
    }
  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
