import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ModalModule } from './_modal';
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatBadgeModule,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MatTableFilterModule } from 'mat-table-filter';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SigmentsService } from "./services/sigments.service";
import { LogisticsComponent } from "./components/step-two/logistics/logistics.component";
import { StepTwoComponent } from "./components/step-two/step-two.component";
import { TrackingStatusComponent } from "./components/tracking-status/tracking-status.component";
import { StepFourComponent } from "./components/step-four/step-four.component";
import { SortPipe } from "./services/sort.pipe";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment.prod";
import { AuthService } from "./services/auth.service";
import { SearchComponent } from './components/search/search.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { from } from 'rxjs';
import { LayoutModule } from '@angular/cdk/layout';
import { SigmentChartComponent } from './components/sigment-chart/sigment-chart.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AddEmaillSnackBarComponent } from './components/add-emaill-snack-bar/add-emaill-snack-bar.component';
import { StepFourLogisticsComponent } from './components/step-four/logistics/step-four-logistics.component';
import { BookingComponent } from './components/booking/booking.component';
import { BookingDetailsComponent } from './components/booking/booking-details/booking-details.component';
import { MaintenanceComponent } from './components/booking/maintenance/maintenance.component';
import { DatePipe } from '@angular/common';
import { FritzGraphComponent } from './components/fritz-graph/fritz-graph.component';
import { FritzCanvasComponent } from './components/fritz-canvas/fritz-canvas.component';
import{GoogleAnalyticsService} from './services/google-analytics.service';
import { ArchiveComponent } from './components/archive/archive.component';
import { ShowDocumentsComponent } from './components/archive/show-documents/show-documents.component';
import { SendEmailComponent } from './components/send-email/send-email.component'
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogisticsComponent,
    StepTwoComponent,
    TrackingStatusComponent,
    StepFourComponent,
    SortPipe,
    SearchComponent,
    LoadingComponent,
    DashboardComponent,
    SigmentChartComponent,
    ReportsComponent,
    AddEmaillSnackBarComponent,
    StepFourLogisticsComponent,
    BookingComponent,
    BookingDetailsComponent,
    MaintenanceComponent,
    FritzGraphComponent,
    FritzCanvasComponent,
    ArchiveComponent,
    ShowDocumentsComponent,
    SendEmailComponent
  ],
  imports: [
    ModalModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    HttpClientModule,
    FlexLayoutModule,
    ChartsModule,
    MatTableFilterModule,
   
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    LayoutModule,
  ],
  entryComponents: [StepFourComponent,AddEmaillSnackBarComponent,StepFourLogisticsComponent,BookingDetailsComponent,MaintenanceComponent,FritzGraphComponent,ShowDocumentsComponent,SendEmailComponent],
  providers: [SigmentsService, AuthService,DatePipe,ThemeService,GoogleAnalyticsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
