import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StepTwoComponent } from './components/step-two/step-two.component';
import { LogisticsComponent } from './components/step-two/logistics/logistics.component';
import { ReportsComponent } from './components/reports/reports.component';
import { BookingComponent } from './components/booking/booking.component';
import { ArchiveComponent } from "./components/archive/archive.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "home",component:HomeComponent , children: [
        { path: 'dashboard', component:DashboardComponent },
        { path: 'booking', component:BookingComponent },
        { path: 'Archive', component:ArchiveComponent },
        
        { path: "AI", component: StepTwoComponent },
        { path: "AE", component: StepTwoComponent },
        { path: "OI", component: StepTwoComponent },
        { path: "OE", component: StepTwoComponent },
        { path: "DELIVERY", component: StepTwoComponent },
        { path: "VS", component: StepTwoComponent },
        { path: "FOLLOW", component: StepTwoComponent },
        { path: "LOGISTIC", component: LogisticsComponent },
        { path: "reports", component: ReportsComponent }
      ]
    }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
