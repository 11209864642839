import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {


  header: HttpHeaders;
  readonly url = environment.GetApiRootUrl();
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  getAmitalInvoices(): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(`${this.url}api/archive/getAmitalInvoices`, {
      headers: this.header,
    });
  }
  getDocs(sigment, tickId) {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(`${this.url}api/archive/getDocs/${sigment}/${tickId}`, {
      headers: this.header,
    });
  }
  getInvoices(): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(`${this.url}api/archive/getInvoices`, {
      headers: this.header,
    });
  }
  getInvoicesList1(): Observable<any> {
    this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(`${this.url}api/archive/getInvoicesList1`, {
      headers: this.header,
    });
  }
}
