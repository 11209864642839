import {
  Component,
  OnInit,
  Inject,
  HostListener,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MAT_DATE_LOCALE } from "@angular/material";
import { OrgService } from "src/app/services/org.service";
import { FormBuilder, FormControl, FormControlDirective, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { SigmentsService } from 'src/app/services/sigments.service';
import { element } from "protractor";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { BookingService } from 'src/app/services/booking.service';
import { map, startWith } from "rxjs/operators";
import { DatePipe } from '@angular/common';
import * as moment from "moment";

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.scss"],

  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class BookingDetailsComponent implements OnInit {
  consigneeFilteredOptions;
  shippingAgentFilteredOptions;
  destinationFilteredOptions;
  finalDestinationFilteredOptions;
  loadingPortFilteredOptions;
  vesselFilteredOptions;
  isEdit = false;
  dataSaved = false;
  bookForm: any;
  bookingParamByType: any = {
    "Shipping_Agent": [],
    "Destination": [],
    "Final_Destination": [],
    "Loading_Port": [],
    "vessel": [],
    "Container_Type": [],
    "Consignee": []
  }
  NilitPreviousVessel:any=[];

  constructor(

    private dialogRef: MatDialogRef<BookingDetailsComponent>,
    private bookingService: BookingService,
    private formbulider: FormBuilder,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public myData: any
  ) {
  }
  private _filter(value): string[] {
    let filterValue = value.toLowerCase();

    return this.bookingParamByType['Consignee'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _shippingAgentFilter(value): string[] {
    let filterValue = value.toLowerCase();
    return this.bookingParamByType['Shipping_Agent'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _destinationFilter(value): string[] {
    let filterValue = value.toLowerCase();

    return this.bookingParamByType['Destination'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  private _finalDestinationFilter(value): string[] {
    let filterValue = value.toLowerCase();

    return this.bookingParamByType['Final_Destination'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  private _loadingPortFilter(value): string[] {
    let filterValue = value.toLowerCase();

    return this.bookingParamByType['Loading_Port'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  private _vesselFilter(value): string[] {
    let filterValue = value.toLowerCase();

    return this.bookingParamByType['vessel'].filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {

    this.bookingService.getBookingParamByType().subscribe(data => {
      this.bookingParamByType = JSON.parse("" + data);
      this.bookingParamByType['Container_Type'].forEach(element => {
        this.bookForm.controls["containerTypeQuantity"].controls[element] = new FormControl((this.myData.ContainerTypeQuantity != undefined) ? this.myData.ContainerTypeQuantity[element] : '')
      });
    });
   
    if (this.myData.Book != null) {

      this.bookingService.getNilitPreviousVessel(this.myData.Book).subscribe(data => {
        this.NilitPreviousVessel = data;
       
      });

      if (this.myData.Status == 'New' || this.myData.Status == 'Updated'|| this.myData.Status == 'Not_approved') {
        this.isEdit = true;
      }
     
      //
      this.bookForm = this.formbulider.group({
        book: [this.myData.Book, [Validators.required]],
        consignee: [this.myData.Consignee.trim(), [Validators.required]],
        SLine: [this.myData.SLine.trim(), [Validators.required]],
        vessel: [this.myData.Vessel.trim(), [Validators.required]],
        dischargePort: [this.myData.DischargePort.trim(), [Validators.required]],
        finalDestination: [this.myData.FinalDestination.trim()],
        loadingPort: [this.myData.LoadingPort.trim(), [Validators.required]],
        container: [this.myData.Container, [Validators.required]],//new FormControl({value: this.myData.Container/*,disabled: true*/}),
        containerTypeQuantity: this.formbulider.group(
          {

          }
        ),
        // deliveryNum: [this.myData.DeliveryNum.trim()],
        BKD: [this.myData.BKD == 'X' ? false : true],//new FormControl({value: this.myData.BKD/*,disabled: true*/}, Validators.required), 
        status: [this.myData.Status, [Validators.required]],
        sailingNumber: [this.myData.SailingNumber],
        shipCloseDate: [this.myData.ShipCloseDate]

        // shipCloseDate:[this.datePipe.transform(this.myData.ShipCloseDate, 'dd/MM/yyyy')]
      });
    } else {
      this.bookForm = this.formbulider.group({
        book: [0],
        consignee: ['', [Validators.required]],
        SLine: ['', [Validators.required]],
        vessel: ['', [Validators.required]],
        dischargePort: ['', [Validators.required]],
        finalDestination: [''],
        loadingPort: ['', [Validators.required]],
        container: ['', [Validators.required]],//new FormControl({value: ''/*,disabled: true*/}),
        containerTypeQuantity: this.formbulider.group(
          {

          }
        ),
        //  deliveryNum: [''],
        BKD: [false],//new FormControl({value:'X'/*,disabled: true*/}, Validators.required), 
        status: ['New', [Validators.required]],
        sailingNumber: [''],
        shipCloseDate: ['']
      });
    }
    this.consigneeFilteredOptions = this.bookForm.controls['consignee'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.shippingAgentFilteredOptions = this.bookForm.controls['SLine'].valueChanges.pipe(
      startWith(''),
      map(value => this._shippingAgentFilter(value))
    );
    this

      .destinationFilteredOptions = this.bookForm.controls['dischargePort'].valueChanges.pipe(
        startWith(''),
        map(value => this._destinationFilter(value))
      );
    this.finalDestinationFilteredOptions = this.bookForm.controls['finalDestination'].valueChanges.pipe(
      startWith(''),
      map(value => this._finalDestinationFilter(value))
    );
    this.loadingPortFilteredOptions = this.bookForm.controls['loadingPort'].valueChanges.pipe(
      startWith(''),
      map(value => this._loadingPortFilter(value))
    );
    this.vesselFilteredOptions = this.bookForm.controls['vessel'].valueChanges.pipe(
      startWith(''),
      map(value => this._vesselFilter(value))
    );
  }
  // changeStatus(value) {
  //   if (value = "Closed") {
  //     this.bookForm.controls["BKD"].setValue('Y');
  //   }
  // }
  onFormSubmit(bookForm) {
    let containerList = []
    for (const [key, value] of Object.entries(this.bookForm.controls["containerTypeQuantity"].controls)) {
      if ((value as any).value != undefined && (value as any).value != "" && (value as any).value != 0) {
        bookForm.containerTypeQuantity[key] = (value as any).value
        containerList.push(`${key}X${(value as any).value}`);
      }
    }
    this.bookForm.controls["container"].setValue(containerList.join('+'))
    bookForm.BKD = this.bookForm.controls["BKD"].value == false ? 'X' : 'Y';
    bookForm.UserEmail = localStorage.getItem("username");
    if (this.bookForm.valid) {
      bookForm["ShipCloseDateFormat"] = this.datePipe.transform(bookForm.shipCloseDate, 'dd/MM/yyyy');
      this.bookingService.save(bookForm).subscribe(data => {
        this.dialogRef.close();
      });
    }
  }
  close(): void {
    this.dialogRef.close();
  }
}