import { Component, OnInit, SimpleChanges, Inject } from "@angular/core";
import { SigmentsService } from "src/app/services/sigments.service";
import { ActivatedRoute } from "@angular/router";
import { cloneDeep } from "lodash";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { environment } from "src/environments/environment";
import { OrgService } from 'src/app/services/org.service';
import { Observable } from 'rxjs';
import * as moment from "moment";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";

@Component({
  selector: "app-step-four",
  templateUrl: "./step-four.component.html",
  styleUrls: ["./step-four.component.scss"],
})
export class StepFourComponent implements OnInit {
  readonly environment = environment;
  sub: any;
  //app
  activity: string = "AI";
  tikId: string;
  orgId: string;
  po: string;
  data: any;
  showSpinner: boolean = true;
  //resource by language
  Resx: any;
  hbl: string;
  sku: string;
  tableOne: any;
  tableTwo: any;
  tableTwoSort: any;
  tableThree: any;
  tableFour: any;
  tableFourSort: any;
  tableFive: any;
  tOneHeaders: any;
  tThreeHeaders: any;
  tFourHeaders: any;
  tFiveHeaders: any;
  tTwoHeaders: any;
  docs: any;
  propChanges: SimpleChanges;
  docsLength: any;
  displaySerivtz = false;
  isActive = false;
  OHSRLN;
  widthOftableTwo: string = "100%";
  widthOftableThree: string = "100%";
  widthOftableFour: string = "100%";
  /**
   * ctor
   * @param sigmentsSrv
   */
  constructor(
    private sigmentsSrv: SigmentsService,
    private dialogRef: MatDialogRef<StepFourComponent>,
    private orgService: OrgService,
    public googleAnalyticsService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public myData: any
  ) {
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
  }
  SendLogEvent(action: string) {
    //We call the event emmiter function from our service and pass in the details
    this.googleAnalyticsService.eventEmitter("View information", `Step 4|${this.myData.activity}|${action}`, `Step 4`, 1);
  }
  ngOnInit() {
    this.SendLogEvent("Open");

    this.activity = this.myData.activity
    this.tikId = this.myData.tikId || null;
    this.po = this.myData.po || null;
    this.hbl = this.myData.hbl || "0";
    this.sku = this.myData.sku || null;
    this.OHSRLN = this.myData.OHSRLN || null;
    this.getDataFromApi().subscribe(() => {
      this.orgService.isExistsOrg(this.orgId).subscribe(result => {
        this.displaySerivtz = result;
        this.isActive = false;
      });
    });
  }
  parseData(obs, data) {
    this.data = data;
    this.tableOne = this.data.TblOne;
    this.tableTwo = this.data.TblTwo;
    this.tableThree = this.data.TblThree;
    this.tableFour = this.data.TblFour;
    this.tableFive = this.data.TblFive;
    this.tOneHeaders = this.data.Headers_TblOne;

    this.tTwoHeaders = this.data.Headers_TblTwo;
    this.widthOftableTwo = `${this.tTwoHeaders.length * 141}px`;
    this.tThreeHeaders = this.data.Headers_TblThree;
    if (this.tThreeHeaders != undefined) {
      this.widthOftableThree = `${this.tThreeHeaders.length * 141}px`;
    }
    if (this.data.Headers_TblThree_ToRemove != undefined) {
      this.tThreeHeaders = this.tThreeHeaders.filter((el) => !this.data.Headers_TblThree_ToRemove.includes(el));
    }
    if(this.data.Headers_TblFour!=undefined){
    this.tFourHeaders = this.data.Headers_TblFour.filter(x => x != 'TSUPDTDate');
    this.widthOftableFour = `${this.tFourHeaders.length * 141}px`;
    }

    this.tFiveHeaders = this.data.Headers_TblFive;

    if (this.data.TblRefDoc) {
      this.docs = this.data.TblRefDoc;
      this.docsLength = this.docs.length;
    }
    if (this.data.OrgEntity != null)
      this.orgId = this.data.OrgEntity.CRM ? this.data.OrgEntity.CRM : "0";
    obs.next();
  }
  getDataFromApi(): Observable<any> {
    this.isActive = true;
    return new Observable(obs => {
      if (this.activity == "FOLLOW" || this.activity == "VS") {
        this.sigmentsSrv
          .getStageFourData(this.activity, 0, 0, this.po, this.po, "0", this.OHSRLN)
          .then((data) => {
            this.parseData(obs, data);
          });
      } //else if (this.activity == "LOGISTIC") {
      //   this.sigmentsSrv
      //     .getStageFourData(this.activity, 0, 0, this.sku, this.po)
      //     .then((data) => {
      //       this.parseData(obs, data);
      //     });
      // }
      else {
        this.sigmentsSrv
          .getStageFourData(this.activity, 0, 0, this.po, this.tikId, this.hbl)
          .then((data) => {
            this.parseData(obs, data);
          });
      }
    });
  }

  OpenImportDoc(_url) {
    var rootUrl = environment.GetApiRootUrl();
    if (_url.includes(rootUrl)) {
      this.SendLogEvent("Download");
      this.sigmentsSrv.getPdf(_url).then((data) => {

        var file = new Blob([data], { type: data.type });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      });
    } else {
      window.open(_url, "_blank");
    }
  }

  // OpenExportDoc(_url) {
  //   var rootUrl = environment.GetApiRootUrl();
  //   if (_url.includes(rootUrl)) {
  //     this.sigmentsSrv.getPdf(_url).then((data) => {
  //       var file = new Blob([data], { type: data.type })
  //       var fileURL = URL.createObjectURL(file);
  //       window.open(fileURL, "_blank");
  //     });
  //   } else {
  //     window.open(_url, "_blank");
  //   }
  // }

  getLastPOD(linkPOD) {
    linkPOD = linkPOD.replace(":", "_points_");
    this.sigmentsSrv.getLastDoc(linkPOD).then((data) => {
      if (data != null) {
        this.SendLogEvent("Download");
        var file = new Blob([data], { type: data.type });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        console.log("NO DATA FOUND");
      }
    });
  }
  close() {
    this.dialogRef.close();
  }
}
