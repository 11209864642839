import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  public createAsExcelFile(json: any[], headers?: string[]):any{
    let worksheet: XLSX.WorkSheet;
      if (headers) {
        worksheet = XLSX.utils.json_to_sheet(json, { header: headers });
      } else {
        worksheet = XLSX.utils.json_to_sheet(json);
      }
  
      const workbook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"]
      };
  
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
    
      return excelBuffer;
   }
    public exportAsExcelFile(json: any[], excelFileName: string, headers?: string[]): void {
      const excelBuffer = this.createAsExcelFile(json,headers);
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  
    private saveAsExcelFile(excelBuffer: any, fileName: string): void {
      const data: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }
}
