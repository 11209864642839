import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: "root"
})
export class ReportsService {
  readonly url = environment.GetApiRootUrl();

  constructor(private http: HttpClient,private authService:AuthService) { }

  sendMail(sendReportToMail:any){
    // const formData = new FormData();
    // formData.append("file", btoa(String.fromCharCode.apply(null, file)));
    return this.http.post<any>(this.url + `api/report/sendMail`,sendReportToMail, {headers: this.authService.GetHeaderToken()});
  }
  getReports(){
    return this.http.get<any>(this.url + "api/report/listSQL", {
      headers: this.authService.GetHeaderToken(),
    });
  }

  getReport(excelMenu: any) { 
    return this.http.post<any>(this.url + "api/report/get",excelMenu, {headers: this.authService.GetHeaderToken()});
  }
}
