import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { SigmentsService } from "src/app/services/sigments.service";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { StepFourComponent } from "../step-four/step-four.component";
import { StepFourLogisticsComponent } from '../step-four/logistics/step-four-logistics.component';

@Component({
  selector: "app-tracking-status",
  templateUrl: "./tracking-status.component.html",
  styleUrls: ["./tracking-status.component.scss"],
})
export class TrackingStatusComponent implements OnInit {
  @Input() trackingData;
  activeSigments: any;
  lastStatus: any;
  statusList: any;
  parentSigment: any;
  isExport: any;
  statusBySigments: any;
  sigmentsLength: any;
  toggleActive: boolean = false;
  Resx: any;
  stepFourData = {};
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private sigmentsSrv: SigmentsService
  ) { }

  ngOnInit() {
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
    //init active sigments


    this.activeSigments = this.trackingData.StatusBySigments;

    //init last status
    this.lastStatus = this.trackingData.StatusList[
      this.trackingData.StatusList.length - 1
    ];
    this.statusList = this.trackingData.StatusList;
    this.toggleActive = false;
  }

  GotoStaus(activity) {
    this.toggleActive = !this.toggleActive;
    if (activity != undefined) {
      switch (activity) {
        case "LOGISTIC":
          this.stepFourData = {
            activity: activity,
            po: this.trackingData.ORDERID,
            sku: this.trackingData.SKU,
          };
          break;
        case "OE":
          this.stepFourData = {
            activity: activity,
            tikId: this.trackingData.TIK,
            hbl: this.trackingData.HBL,
          }
          break;

        case "VS":
          this.stepFourData = { activity: activity, po: this.trackingData.EXTik }
          break;
        case "AE":
        case "AI":
        case "OI":
          this.stepFourData ={ 
            activity: activity,
            tikId:(this.trackingData.TIK != undefined) ? this.trackingData.TIK : this.trackingData.TIKID
          }
          break;
        case "DELIVERY":
          this.stepFourData = {
            activity: activity,
            tikId: (this.trackingData.TIKID != undefined) ? this.trackingData.TIKID : this.trackingData.TIK,
            po: this.trackingData.PO
          }
          break;
        case "FOLLOW":
          this.stepFourData = { activity: activity, po: this.trackingData.PO,OHSRLN:this.trackingData.OHSRLN }
          break;
        // default:
        //   if (this.tikId) {
        //     this.router.navigate(["/"], {
        //       queryParams: { activity: activity, tikId: this.tikId },
        //     });
        //     this.openDetailsdialog();
        //   } else {
        //     this.router.navigate(["/"], {
        //       queryParams: { activity: activity, po: this.trackingData.po },
        //     });
        //     this.openDetailsdialog();
        //   }
        //   break;
      }
      this.openDetailsdialog(this.stepFourData);
    }
  }
  close() {
    this.toggleActive = !this.toggleActive;
  }

  openDetailsdialog(stepFourData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = stepFourData;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "90%";
    dialogConfig.position = {
   
    };
    {
      dialogConfig.disableClose = true;
    }
    if (stepFourData.activity == "LOGISTIC") {
     // this.dialog.open(StepFourLogisticsComponent, dialogConfig);
    } else {
      this.dialog.open(StepFourComponent, dialogConfig);
    }

  }
  // openStepFour() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = {
  //     title: "Modal",
  //   };
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = "90%";
  //   dialogConfig.height = "520px";
  //   dialogConfig.disableClose = true;
  //   this.dialog.open(StepFourComponent, dialogConfig);
  // }
}
