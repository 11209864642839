import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  bookingParam=""
  displayedColumns=["name"];
  bookingParamByType: any = {
    "Shipping_Agent": [],
    "Destination": [],
    "Final_Destination": [],
    "Loading_Port": [],
    "vessel": [],
    "Container_Type": [],
    "Consignee":[]
  }
  constructor(private bookingService:BookingService,private dialogRef:MatDialogRef<MaintenanceComponent>) { }

  ngOnInit(): void {
  this.load();
  }
  load(){  
    this.bookingService.getBookingParamByType().subscribe(data => {
      this.bookingParamByType = JSON.parse("" + data);
    });

  }
  addNew(type){
    this.bookingService.saveBookingParamByType(type,this.bookingParam).subscribe(data => {
      this.bookingParam="";
      this.load();
    });
 }
  close(): void {
    this.dialogRef.close();
  }

}
