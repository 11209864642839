import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogConfig,
  MatSnackBar,
} from "@angular/material";
import * as copy from 'copy-to-clipboard';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingService } from 'src/app/services/booking.service';
import { SigmentsService } from 'src/app/services/sigments.service';
import { timeStamp } from 'console';
import { ExportService } from 'src/app/services/export.service';
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { DatePipe, formatDate } from "@angular/common";
import * as moment from "moment";
import { Subscription } from "rxjs";
@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit, OnDestroy {
  query: any = undefined;
  newRowIndex = 0;
  dataSaved = false;
  isLoading = false;
  isDisplaySearch=false;
  displayedColumns;
  displayedColumnsSearch;
  data: any = [];
  Resx;
  statusList = ["New",
    "Closed",
    "Updated",
    "Not_approved",
    "Canceled"]
  filterEntity: any = {
    "Status": ["New", "Updated", "Not_approved"]
  };
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  shipDate: Date;
  private subscriptions: Subscription = new Subscription();
  widthOfReportTable: string="100%";
  search() {
    this.bookingService.list(this.filterEntity).subscribe(bookingEntity => {
      let data = JSON.parse("" + bookingEntity);
      this.data = data.BookingList;
      this.dataSource.data = this.data;
    });
    //this.loadBooking();
    // this.dataSource.filter = JSON.stringify(this.filterEntity);
  }
  displaySearch() {
    this.isDisplaySearch = !this.isDisplaySearch;
    if (this.isDisplaySearch === false) {
      this.onSearchClear();
    }
  }
  onSearchClear() {
    this.filterEntity = {
      "Status": ["New", "Updated", "Not_approved"]
    };
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  applySearch(data: any, filter: string) {
    let filterEntity = JSON.parse(filter)
    let result = true;
    Object.keys(filterEntity).forEach(key => {
      if (filterEntity[key] != null) {
        if (key === 'value') {
          if (filterEntity[key].trim() != '') {
            if (!JSON.stringify(data).trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
              result = false;
            }
          }
        }
        if (key === 'Status') {
          if (filterEntity['Status'].length > 0 && !filterEntity['Status'].includes(data.Status)) {
            result = false;
          }
        }
        if (key === 'ShipCloseDate') {
          if (data.ShipCloseDate == null && filterEntity[key] != '')
            result = false;
          else {
            let ss = formatDate(data.ShipCloseDate, 'dd/MM/yy', 'en-US').trim();
            if (filterEntity['ShipCloseDate'].length > 0 && !ss.includes(filterEntity['ShipCloseDate'])) {
              result = false;
            }
          }
        }
        // else {
        //   if (!data[key].toString().trim().toLowerCase().includes(filterEntity[key].trim().toLowerCase())) {
        //     result = false;
        //   }
        // }
      }
    });
    return result;
  }

  getStringDateFormat(baseData): string {

    let date = baseData.split('/');
    let newDate = (date[1] + '/' + date[0] + '/' + date[2])

    const dateObj = new Date(newDate).toDateString();
    return dateObj
  }
  newBook() {
    this.openDetailsdialog({});
  }
  loadBooking() {
    this.isLoading = true;
    this.bookingService.list(this.filterEntity).subscribe(bookingEntity => {
      let data = JSON.parse("" + bookingEntity);
      this.data = data.BookingList;
      this.displayedColumns = data.Headers_Booking;
      this.displayedColumns.unshift("Copy");
      this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //this.dataSource.filterPredicate = this.applySearch;
      this.isLoading = false;
      this.widthOfReportTable = `${this.displayedColumns.length * 141}px`;
      //this.search();
    });
  }
  copyBook(book) {
    delete book["ShipCloseDateFormat"];
    delete book["ContainerTypeQuantity"];
    delete book["BKD"];
    delete book["Status"];
    delete book["UserEmail"];
    delete book["CreateDate"];
    delete book["CRM"];
    copy(Object.keys(book).join('\t') + '\r\n' + Object.values(book).join('\t'), { format: 'text/plain' });
  }
  export() {
    let myObject = this.dataSource.filteredData;
    for (let i = 0; i < myObject.length; i++) {
      delete myObject[i]["ShipCloseDateFormat"];
      delete myObject[i]["ContainerTypeQuantity"];
      let temp = this.datepipe.transform(myObject[i]["ShipCloseDate"], 'yyyy-MM-dd');
      myObject[i]["ShipCloseDate"] = temp;
    }


    this.exportService.exportAsExcelFile(myObject, "booking");
  }
  maintenance() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.autoFocus = true;
    dialogConfig.width = "87%";
    dialogConfig.height = "90%";
    dialogConfig.position = {
     
    };
    {
      dialogConfig.disableClose = true;
    }
    this.subscriptions.add(this.dialog.open(MaintenanceComponent, dialogConfig).afterClosed().subscribe(() => {
      this.afterAllClosed();
    }
    ));
  }
  ngOnInit() {
    this.loadBooking();
  }
  editBook(bookEdit) {
    this.bookingService.getBookingContainerList(bookEdit.Book).subscribe(data => {
      bookEdit.ContainerTypeQuantity = JSON.parse("" + data);
      this.openDetailsdialog(bookEdit)

    });
  }
  openDetailsdialog(bookEdit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = bookEdit;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "87%";
    dialogConfig.height = "90%";
    dialogConfig.position = {
     
    };
    {
      dialogConfig.disableClose = true;
    }
    this.subscriptions.add(this.dialog.open(BookingDetailsComponent, dialogConfig).afterClosed().subscribe(() => {
      this.afterAllClosed();
    }
    ));
  }
  afterAllClosed() {
    this.loadBooking();
  }
  constructor(
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private bookingService: BookingService,
    private sigmentsSrv: SigmentsService,
    private exportService: ExportService) {
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
  }
}