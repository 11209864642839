import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'angular-bootstrap-md';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-fritz-canvas',
  templateUrl: './fritz-canvas.component.html',
  styleUrls: ['./fritz-canvas.component.scss']
})
export class FritzCanvasComponent implements OnInit {
  showChart = false;
  @Input() sigmentData: []
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  public barChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40, 8, 9, 10, 11, 12], label: 'Series A' },

  ];
  public barChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(225,10,24,0.2)',
      borderColor: 'rgba(225,10,24,0.2)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    {
      backgroundColor: 'rgba(78, 180, 189, 1)',
      borderColor: 'rgba(78, 180, 189, 1)',
      pointBackgroundColor: 'rgba(78, 180, 189, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(78, 180, 189, 1)'
    }
  ];
  public barChartOptions: any = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            fontSize: 14
          }
        }
      ]
    }
  }

  constructor() { }

  ngOnInit(): void { 
    if (this.sigmentData != undefined && this.sigmentData.length > 0) {
      this.barChartData = [];
      this.sigmentData.forEach(a => {
        this.barChartData.push({
          data: [a["M01"], a["M02"], a["M03"], a["M04"], a["M05"], a["M06"], a["M07"], a["M08"], a["M09"], a["M10"], a["M11"], a["M12"]],// a.Month_data,
          label: a['Year_Month']
        });
      });
      this.barChartData.forEach(item => {
        let notNull = item['data'].some(function (el) {
          return el !== null;
        });
        if (notNull)
          this.showChart = true;
      });
    }
  }

}
