import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  CurrentUser;
  errMsg: string;
  GetHeaderToken(): HttpHeaders {
    if (!this.IsUserLoggedIn()) {
      this.router.navigate(["/login"]);
    }
    let token = localStorage.getItem("token");
    let header = new HttpHeaders().set("Authorization", token);
    return header;
  }

  SetToken(token: string, username: string, expiredDate: string) {
    localStorage.setItem("token", "Bearer " + token);
    localStorage.setItem("username", username);
    localStorage.setItem("expiredDate", expiredDate);
    this.CurrentUser = username;
  }

  GetUser(): string {
    return localStorage.getItem("username");
  }

  GetIsFirstLogin(): any {

    let header = this.GetHeaderToken();
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.GetApiRootUrl() + "api/Account/GetIsFirstLogin", {
          headers: header,
        })
        .toPromise()
        .then((data) => {
          resolve(JSON.parse("" + data));
        })
        .catch((err) => {
          resolve(false);
        });
    });
    return promise;
  }

  IsUserLoggedIn(): boolean {
    let expired = localStorage.getItem("expiredDate");
    let expiredDate = new Date(expired);
    var currentDate = new Date();
    if (
      expired !== null &&
      expiredDate instanceof Date &&
      !isNaN(expiredDate.getDate()) &&
      currentDate <= expiredDate
    ) {
      if (
        localStorage.getItem("token") !== null &&
        localStorage.getItem("username") !== null
      ) {
        return true;
      }
    }

    return false;
  }

  UserAuthentication(userName, password): any {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    var data =
      "grant_type=password&username=" + userName + "&password=" + password;
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(environment.GetApiRootUrl() + "token", data, {
          headers: reqHeader,
        })
        .toPromise()
        .then((data) => {
          let token = data["access_token"];
          let username = data["userName"];
          let expiredDate = data[".expires"];
          this.SetToken(token, username, expiredDate);
          resolve("");
        })
        .catch((err) => {
          resolve("incorrect");
        });
    });
    return promise;
  }

  ChangePassword(oldpassword, newpassword, confirmpassword): any {
    var reqHeader = this.GetHeaderToken();
    reqHeader.set("Content-Type", "application/json");
    var data = {
      OldPassword: oldpassword,
      NewPassword: newpassword,
      ConfirmPassword: confirmpassword,
    };
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          environment.GetApiRootUrl() + "api/Account/ChangePassword",
          data,
          { headers: reqHeader }
        )
        .toPromise()
        .then((data) => {
          resolve("success");
        })
        .catch((err) => {
          resolve(err.error.Message);
        });
    });
    return promise;
  }

  LogOut() {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("expiredDate");
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}
}
