export const environment = {
  currentLang: 2,
  production: true,
  GetApiRootUrl() {
    return "https://360webapi02.fritz.co.il/"
   // return "https://360api.fritz.co.il/";
 //  return "http://localhost:64195/";
    //return 'http://90.0.0.28/360Api';
  },
  GetServitzAppRootUrl(){
    //return "https://servitz.firebaseapp.com/";
    return "https://servitzdmznew.firebaseapp.com/";
    //return "http://localhost:4200/";
  }
};
