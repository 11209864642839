import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root",
})
export class OrgService {
  header: HttpHeaders;
  readonly url = environment.GetApiRootUrl();
  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    ) {}


    isExistsOrg(crm: string): Observable<any> {
      this.header = this.authService.GetHeaderToken();
    return this.http.get<any>(this.url + "api/org/isExistsOrg?crm=" + crm, {
      headers: this.header,
    });
  }
}