import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Input, Output, EventEmitter, AfterViewInit, ElementRef } from "@angular/core";
import { MatSort, MatPaginator, MatTableDataSource, MatTable } from "@angular/material";
import { SigmentsService } from "src/app/services/sigments.service";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { merge } from 'lodash';
import { fromEvent } from 'rxjs';
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";

@Component({
  selector: "app-step-two",
  templateUrl: "./step-two.component.html",
  styleUrls: ["./step-two.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("0.5s cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
    trigger("openClose", [
      state(
        "open",
        style({
        })
      ),
      state(
        "closed",
        style({
          height: "0px",
        })
      ),
    // transition("open => closed", [animate("0.5s")]),
    // transition("closed => open", [animate("0.5s")]),
    // transition("* => closed", [animate("0s")]),
    // transition("* => open", [animate("0.5s")]),
    // transition("open <=> closed", [animate("0.5s")]),
    // transition("* => open", [animate("0.5s", style({ opacity: "*" }))]),
    // transition("* => *", [animate("0s")]),
      ]),
  ],
})
export class StepTwoComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('Filter', { static: false }) filter: ElementRef;
  dataSource;
  isDisplaySearch = false;
  data: any[] = [];
  expandedElement: any | null;
  isLoading = false;
  //sigments category list
  categoryList: any[];
  columnsToDisplay = [];
  columnsToDisplaySearch = []
  sigments: any = { data: [], total_count: 0 };

  activeSigment: string = "AE";
  size: number = 10;
  order: string = "";
  Resx: any;
  isOpenChart = false;
  orderBy = "default";
  isDest = false;
  filterEntity: any = {};
  //Charts
  public chartType: string = "bar";
  ChartDatasets: any;
  ChartLabelsList: any[];
  OpenShipment: any[];
  aeWeightList: any[];
  widthOfTable = "100%";
  ChartColors: Array<any> = [
    {
      backgroundColor: [
        "#B3E5FC",
        "#2F80ED",
        "#38788c",
        "#4daecc",
        "#BA68C8",
        "#E57373",
        "#81C784",
        "#FFB74D",
        "#FFF176",
      ],
    },
  ];
  chartOptions: any = {
    responsive: true,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = "left";
        ctx.fillStyle = "black";
        ctx.textBaseline = "bottom";

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 3);
          });
        });
      },
    },
    legend: {
      labels: {
        fontColor: "#38788c",
        fontSize: 20,
        boxWidth: 0,

      },
    },
  };
  hasNoData: boolean;

  constructor(
    private route: ActivatedRoute,
    private sigmentsSrv: SigmentsService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    route.url.subscribe(() => {
      this.activeSigment = route.snapshot.url[0].path;
    });
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.orderBy = this.sort.active;
      this.isDest = this.sort.direction == "asc" ? true : false;
      this.paginator.pageIndex = 0;
      this.getData(this.paginator.pageIndex, this.paginator.pageSize);
    });

    this.paginator.page
      .pipe(
        tap(() => this.getData(this.paginator.pageIndex, this.paginator.pageSize))
      )
      .subscribe();
  }

  ngOnInit() {
    this.isLoading = true;
    this.isOpenChart = false;
    //this.getCharts();
    this.getData(0, 10)
    this.sigmentsSrv.GetResx().then((resx) => {
      this.Resx = resx;
    });
  }
  displaySearch() {
    this.isDisplaySearch = !this.isDisplaySearch;
    if (this.isDisplaySearch === false) {
      this.onSearchClear();
    }
  }
  search() {
    this.paginator.pageIndex = 0;
    this.order = JSON.stringify(this.filterEntity);
    this.getData(this.paginator.pageIndex, this.paginator.pageSize)
    //this.dataSource.filter = JSON.stringify(this.filterEntity);
  }
  onSearchClear() {
    this.filterEntity = {};
  }
  SendLogEvent(sig: string, stg: string) {
    //We call the event emmiter function from our service and pass in the details
    this.googleAnalyticsService.eventEmitter("View information",`Step ${stg}|${sig}` , `Step ${stg}`, 1);
  }
  getData(pageIndex, pageSize) {
    this.SendLogEvent(this.activeSigment, "2");
    this.sigmentsSrv.getStageTwoData(this.activeSigment, pageSize, pageIndex + 1, this.order, this.orderBy, this.isDest).subscribe(data => {
      if (data) {
        this.sigments = JSON.parse("" + data);
        this.columnsToDisplay = this.sigments.Headers_Movment;
        this.columnsToDisplaySearch = this.columnsToDisplay.map(c => c + 'Search');
        const index = this.columnsToDisplay.indexOf("Activities", 0);
        if (index > -1) {
          this.columnsToDisplay.splice(index, 1);
          this.columnsToDisplaySearch.splice(index, 1);
        }
        this.dataSource = this.sigments.MovmentTbl// new MatTableDataSource(this.sigments.MovmentTbl);

        this.paginator.length = this.sigments.TotalRows_Movment
        this.isLoading = false;
        this.widthOfTable = `${this.columnsToDisplay.length * 141}px`;
        // this.dataSource = new MatTableDataSource(this.sigments.MovmentTbl);
        // this.dataSource = new LessonsDataSource(this.coursesService);
        // this.dataSource.loadLessons(this.course.id, '', 'asc', 0, 3);
        //
        // this.dataSource.paginator = this.paginator;
      }
      this.isLoading = false;
    });
  }

  applyFilter(value: string) {
    this.paginator.pageIndex = 0;
    this.order = value.trim();
    this.getData(this.paginator.pageIndex, this.paginator.pageSize)
  }
  toggleRow(element: any) {
    element
      ? (this.expandedElement =
        this.expandedElement === element ? null : element)
      : null;

    if (this.expandedElement != null)
      this.SendLogEvent(this.activeSigment, "3");
  }
  toggle() {
    if(this.isOpenChart==false)
      this.getCharts();
    else
      this.isOpenChart = !this.isOpenChart;   
  }

  getCharts() {
    this.sigmentsSrv.getStageOneData().then((data) => {
      this.data = data;
      this.sigmentsSrv.getSigmentsCategory().then((categoryJson) => {
        this.categoryList = categoryJson;
      });
      switch (this.activeSigment) {

        case "FOLLOW":
        case "DELIVERY":
        case "VS":
          this.hasNoData = true;
          break;
        default:
          this.hasNoData = false;
          //AE
          var aeResult = this.data[this.activeSigment].DataRows.map((item) => [
            item.RIGION,
            item.WEIGHT,
            item.OPENSHIPMETS,
          ]);
          var aeRigionList = [];
          var aeWeightList = [];
          var aeShipmentList = [];
          for (let obj of aeResult) {
            aeRigionList.push(obj[0]);
            aeWeightList.push(obj[1]);
            aeShipmentList.push(obj[2]);
          }
          this.ChartLabelsList = aeRigionList;
          this.aeWeightList = aeWeightList;
          this.OpenShipment = aeShipmentList;
          this.ChartDatasets = [
            { data: aeShipmentList, label: "Open shipments" },
          ];
          break;
      }
      this.isOpenChart = !this.isOpenChart;
    });
  }
}
